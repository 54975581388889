<template>
  <FormWrapper
    :form="form"
    :formName="getFormName"
    :surgeryId="surgeryId"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
  <b-row>
    <b-col>
    <validation-observer 
      :ref="`${form.id}-form`" 
      v-slot="{ invalid }" v-if="canEdit">
    <b-row>
      <b-col cols="12">
        <div class="position-relative">
          <validation-provider name="medicamento" :rules="{ required: true }" v-slot="{ errors, touched }">
            <multiselect
              v-model="fields.name"
              :options="anotacoesOptions"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :loading="isLoading"
              :internal-search="false"
              @search-change="searchSurgicalNotes"
              @select="selectSurgicalNotes"
              @remove="clearFields"
              placeholder="Pesquisar anotação cirúrgica"
              class="search-mode with-border"
              :class="{ 'error': touched && errors.length > 0}"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="single_label d-inline-block text-truncate">{{ props.option }}</span>
              </template>
              <template slot="caret">
                <div class="search">
                  <Search class="search-icon"/>
                </div>
              </template>
              <template slot="noOptions">Digite para pesquisar uma anotação cirúrgica</template>
              <template slot="noResult">Nenhuma anotação cirúrgica encontrada</template>
            </multiselect>
            <div class="new-medical-report">
              <Icon tooltip="Limpa seleção" v-if="fields.name">
                <Close class="icon close" @click="clearFields" />
              </Icon>
              <Icon tooltip="Criar novo modelo de anotação cirúrgica" v-if="showAddCustom()">
                <Plus class="icon" @click="$bvModal.show('surgical-modal')" />
              </Icon>
              <Icon tooltip="Editar" v-if="!showAddCustom()">
                <Edit class="icon edit" @click="$bvModal.show('surgical-modal')"/>
              </Icon>
              <Icon tooltip="Deletar" v-if="!showAddCustom()">
                <Trash class="icon trash" @click="$bvModal.show('delete-modal')" />
              </Icon>
            </div>
          </validation-provider>
        </div>
      </b-col>
    </b-row>
      <b-row>
        <b-col cols="12">
          <div class="form-group">
            <validation-provider name="´anotacao" :rules="{ required: true }">
              <label for="descricao_anotacao">Descrição da anotação</label>
              <VueEditor
                id="template"
                class="vue_edition"
                v-model="fields.template"
                :editorToolbar="customToolbar"
              />
            </validation-provider>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-col cols="2" v-if="editingIndex !== null">
          <b-button
            variant="danger"
            block
            class="mt-10 h-56"
            :disabled="invalid"
            @click="cancelEdition"
          >
            Cancelar edição
          </b-button>
        </b-col>
        <b-col cols="2">
          <b-button
            variant="primary"
            block
            class="mt-10 h-56"
            :disabled="invalid"
            @click="addSurgicalNote"
          >
            {{ editingIndex !== null ? 'Atualizar' : 'Adicionar' }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col>
        <table class="table-eyecare">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, index) of form.value" :key="index">
              <td class="multiple-lines">{{ value.name }}</td>
              <td class="vue_edition">  
                <span v-html="value.template"></span>
              </td>
              <td class="text-right">
                <div class="more-with-menu">
                  <MoreVertical class="more-icon" />
                  <div class="menu">
                    <b-button
                      :disabled="!canEdit"
                      variant="link"
                      @click="editItem(value, index)"
                    >
                      Editar
                    </b-button>
                    <b-button
                      variant="link remove mb-0"
                      :disabled="!canEdit"
                      @click="removeItem(index)"
                    >
                      Remover
                    </b-button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
    <SurgicalNoteModal
      :surgicalNote="selected"
      useProfessional
      @noteCreated="noteCreated"
      @noteUpdated="noteUpdated"
      />
      <DeleteModal
        type="anotação cirúrgica"
        article="a"
        :description="selected.name"
        :confirmedDelete="confirmDelete"
      />
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { VueEditor } from "vue2-editor"

export default {
  components: {
    FormWrapper: () => import ('@/components/Attendance/Forms/FormWrapper'),
    MoreVertical: () => import ('@/assets/icons/more-vertical.svg'),
    VueEditor,
    Icon: () => import ('@/components/General/Icon'),
    Edit: () => import ('@/assets/icons/edit.svg'),
    Trash: () => import ('@/assets/icons/trash.svg'),
    Close: () => import ('@/assets/icons/close.svg'),
    Search: () => import ('@/assets/icons/search.svg'),
    Plus: () => import ('@/assets/icons/plus.svg'),
    SurgicalNoteModal: () => import ('@/components/SurgicalNote/SurgicalNoteModal'),
    DeleteModal: () => import ('@/components/General/DeleteModal'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.anotacaoCirurgica,
      attendance: state => state.attendance.attendance,
    }),
    getFormName() {
      switch (this.attendance?.type) {
        case 'ANESTHETIST_EVOLUTION': return 'Evolução do anestesista'
        case 'TEAM_EVOLUTION': return 'Evolução da equipe multidisciplinar'
        case 'TEAM_ANNOTATIONS': return 'Anotações da equipe multidisciplinar'
        default: return this.form?.name
      }
    },
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      touched: false,
      errors: [],
      isLoading: false,
      anotacoesCirurgicas: [],
      anotacoesOptions: [],
      temporaryReport: '',
      selected: {},
      editingIndex: null,
      surgeryId: this.$route.params.surgeryId,

      fields: this.getDefaultFields(),
        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
    }
  },
  async mounted() {
    await this.getSurgicalNotes()
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/anotacaoCirurgica', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        name:null,
        template: null,
        professional_id: null,
      }
    },
    addSurgicalNote() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.editingIndex = null
      this.clearFields()
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    showAddCustom() {
      return !this.selected || !Object.keys(this.selected).length
    },
    async confirmDelete(confirm) {
      this.$bvModal.hide('delete-modal')
      if (confirm) await this.deleteSurgicalNote()
    },
    async deleteSurgicalNote() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteSurgical(this.selected.id)
        this.noteDeleted(this.selected)
        this.clearFields()
        this.$toast.success('Modelo de anotação cirúrgica excluída com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    searchSurgicalNotes(query) {
      if (query) {
        this.selected = {}
        this.temporaryReport = query
      }
      this.anotacoesOptions = []
      this.anotacoesCirurgicas.map((note) => {
        if(note.name.toLowerCase().includes(query.toLowerCase())) {
          this.anotacoesOptions.push(note.name)
        }
      })
    },
    async selectSurgicalNotes(query) {
      await this.anotacoesCirurgicas.find((note) => {
        if (note.name === query) {
          this.fields.template = note.template
          this.fields.professional_id = note.professional_id
          if (note.professional_id) {
            this.selected = note
          } else {
            this.selected = {}
          }
        }
      })
    },
    async getSurgicalNotes() {
      try {
        const response = await this.api.getSurgicalNotes(this.user.id)
        this.anotacoesCirurgicas = response.data
        this.anotacoesOptions = response.data.map(note => note.name)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setSurgicalNote(note) {
      this.fields.name = note.name
      this.fields.template = note.template
      this.fields.professional_id = note.professional_id
      this.selected = note
    },
    clearFields() {
      this.fields = this.getDefaultFields()
      this.selected = {}
    },
    cancelEdition() {
      this.editingIndex = null
      this.fields = this.getDefaultFields()
    },
    noteCreated(note) {
      this.anotacoesCirurgicas.push(note)
      this.anotacoesOptions.push(note.name)
      this.setSurgicalNote(note)
    },
    noteUpdated(note) {
      const noteFound = this.anotacoesCirurgicas.find(n => note.id === n.id) 
      noteFound.name = note.name
      noteFound.template = note.template

      this.anotacoesOptions = this.anotacoesCirurgicas.map(n => n.name)
      this.setSurgicalNote(note)
    },
    noteDeleted(note) {
      const index = this.anotacoesCirurgicas.findIndex(n => note.id === n.id) 
      this.anotacoesCirurgicas.splice(index, 1)
      this.anotacoesOptions = this.anotacoesCirurgicas.map(n => n.name) 
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value = []
    }
  },
}
</script>
<style lang="scss" scoped>
textarea {
    min-height: 200px;
    max-height: 1000px;
  }

  .search {
    width: 5% !important;
    div {
      cursor: pointer;
    }
  }

  .single_label {
    max-width: 85%;
  }

  .vue_edition {
    background-color: white;
    
    :deep(.ql-editor) {
      min-height: 100px !important;
    }
  }
  
  .new-medical-report {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
    gap: 10px;

    .icon {
      width: 20px;
      height: 20px;
      fill: var(--blue-500);
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }

      &.close {
        fill: var(--type-active);
      }
    }
  }
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}
.button {
  height: 38px;
  margin-bottom: 24px;
}
.mt-10 {
  margin-top: 10px;
}
</style>
